import React from 'react'

import Links from './Links'
import Logo from './Logo'

import styles from './styles.module.css'

export default function Sidebar(props) {
  const {website, userPreferences, navbarConfig} = props
  if (!website) return null
  const {logo} = navbarConfig
  const finalLogo = logo || website.logo
  if (!props.open) return null

  return (
    <div
      className={props.isSmall ? styles.sidebarSmall : styles.sidebarSuper}
      style={{backgroundColor: navbarConfig.backgroundColor, color: navbarConfig.textColor}}>
      <div onClick={() => props.setOpen(false)} className={styles.container}>
        {finalLogo && (
          <Logo
            config={navbarConfig}
            website={website}
            itemsOnCart={userPreferences ? userPreferences.cart.items.length : 0}
          />
        )}
        <Links
          categories={website.categories}
          links={navbarConfig.links}
          itemsOnCart={userPreferences ? userPreferences.cart.items.length : 0}
          cart={userPreferences && userPreferences.cart ? userPreferences.cart : null}
        />
      </div>
      {props.isSmall ? (
        <div className={styles.shadow} onClick={() => props.setOpen(false)} />
      ) : null}
    </div>
  )
}
