import React, {useEffect, useRef, useState} from 'react'
import DeliveryWizard from '@components/DeliveryWizard'
import usePreferencesForAddressContext from '@components/DeliveryWizard/internals/hooks/usePreferencesForAddressContext.main'
import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import {useTranslation} from 'next-i18next'

import CaretIcon from './Icons/CaretIcon'
import DeliveryIcon from './Icons/DeliveryIcon'

import styles from './styles.module.css'

export default function Delivery() {
  const {preferences, loading} = usePreferencesForAddressContext()
  const {t} = useTranslation('website', {keyPrefix: 'order.cartMenuV2.optionsLabel'})
  const [typeOfDelivery, setTypeOfDelivery] = useState('')
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (preferences.deliveryType === 'delivery') {
      setTypeOfDelivery('Delivery a:')
      if (preferences.address) {
        setDeliveryAddress(preferences.address.address)
      } else {
        setDeliveryAddress(t('address'))
      }
    }

    if (preferences.deliveryType === 'go') {
      setTypeOfDelivery('Retiro en:')

      if (!preferences.store) {
        setDeliveryAddress(t('store'))
      } else {
        setDeliveryAddress(t('storeGo', {store: preferences.store.name}))
      }
    }
  }, [preferences])

  const toggleOpen = () => {
    setOpen(e => !e)
  }

  const closeOpen = () => {
    setOpen(false)
  }

  if (!preferences || loading) return <div className={styles.loading} />

  return (
    <div className={styles.deliveryInfo} ref={ref}>
      <div className={styles.typeOfDelivery} onClick={toggleOpen}>
        <div className={styles.icon}>
          <DeliveryIcon />
        </div>
        <div className={styles.text}>{typeOfDelivery}</div>
      </div>
      <div className={styles.addressInfo} onClick={toggleOpen}>
        {deliveryAddress} <CaretIcon />
      </div>
      <SafeSuspenseLoading>
        <DeliveryWizard.AsModal isOpen={open} onClose={closeOpen} actionLocation="navbar" />
      </SafeSuspenseLoading>
    </div>
  )
}
