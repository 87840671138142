import ClientSuspense from '@components/ClientSuspense'
import Loading from '@components/SuspenseLoading/Loading'
import isServerSide from '@helpers/misc/isServerSide'
import getPathname from '@helpers/router/getPathname'
import useWebsiteId from '@hooks/useWebsiteId'
import useWidth from '@hooks/useWidth'
import useInitialData from '@page-components/Layout/useInitialData'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useRouter} from 'next/router'
import React, {useState} from 'react'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import resumeCartItem from './resumeCartItem'

import styles from './styles.module.css'

export default function TiramisuLayout(props: {children: React.ReactNode}) {
  const websiteId = useWebsiteId()
  const {website} = useInitialData()

  const {userPreferences} = useQuery({
    query: gql`
      query getNavbarConfigTiramisu($websiteId: ID) {
        userPreferences(websiteId: $websiteId) {
          _id
          cart {
            items {
              _id
              isOutOfStock
              amount
              ...resumeCartItem
            }
            bagAmount
            storeId
            deliveryType
            deliveryFee
            serviceFee
            menuId
            itemsPrice
            realPrice: itemsPrice(includeDiscount: false)
            totalPrice
            amountToPay
            calculatedBagsRequired
            maxBagsAllowed
          }
        }
      }
      ${resumeCartItem}
    `,
    variables: {websiteId},
    partial: true,
    omit: isServerSide(),
  })
  const width = useWidth()
  const [stateOpen, setOpen] = useState()
  const router = useRouter()
  const isSmall = width < 1100
  const open = !isSmall || stateOpen
  const navbar = website?.navbarConfiguration || {
    backgroundColor: '#fff',
    textColor: '#111',
  }
  const superBanner = getPathname(router) === '/order'
  const isCheckout = getPathname(router) === '/checkout'

  if (!website) return null
  return (
    <div className={styles.container}>
      <ClientSuspense fallback={null}>
        <Sidebar
          isSmall={isSmall}
          open={open}
          setOpen={setOpen}
          website={website}
          userPreferences={userPreferences}
          navbarConfig={navbar}
        />
      </ClientSuspense>
      <div className={styles.content}>
        {superBanner ? <div className={styles.superBanner} /> : null}
        <ClientSuspense fallback={<Loading />}>
          <Navbar
            isSmall={isSmall}
            open={open}
            setOpen={setOpen}
            website={website}
            userPreferences={userPreferences}
            navbarConfig={navbar}
            isCheckout={isCheckout}
          />
        </ClientSuspense>
        <ClientSuspense fallback={<Loading />}>{props.children}</ClientSuspense>
      </div>
    </div>
  )
}
