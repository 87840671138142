import React from 'react'
import scrollTo from '@helpers/misc/scrollTo'
import useTrackEcommerceEvent, {ECOMMERCE_EVENTS} from '@helpers/track/useTrackEcommerceEvent'
import Link from 'next/link'

import styles from './styles.module.css'

const renderLinks = function (links, itemsOnCart, cart, trackEcommerceEvent) {
  return links.map((link, index) => {
    if (link.path.startsWith('/')) {
      return (
        <Link
          href={link.path}
          key={index}
          className={styles.link}
          onClick={() => {
            if (link.path === '/checkout' && cart) {
              trackEcommerceEvent(ECOMMERCE_EVENTS.INITCHECKOUT, {cart})
            }
          }}>
          {link.title}
          {link.path === '/checkout' ? <div className={styles.cartCount}>{itemsOnCart}</div> : null}
        </Link>
      )
    } else {
      return (
        <a
          href={link.path}
          target="_blank"
          key={index}
          className={styles.link}
          rel="noopener noreferrer">
          {link.title}
        </a>
      )
    }
  })
}

const renderCategories = function (categories) {
  return categories.map(link => (
    <Link
      href={`/pedir?categoria=${link.internalName?.toLowerCase()}`}
      key={link._id}
      onClick={() =>
        setTimeout(() => {
          const categorySpecificId = `t111_${link._id}`
          const category = document.getElementById(categorySpecificId)
          // here 100 is the offset height to account for the layout for mobile
          // devices on this specific setting.
          category && scrollTo({element: category, top: category.offsetTop - 100})
        }, 100)
      }
      className={styles.link}>
      {link.name}
    </Link>
  ))
}

export default function SidebarLinks(props) {
  const {links, itemsOnCart, categories, cart} = props
  const trackEcommerceEvent = useTrackEcommerceEvent()
  if (!links || !links.length) return null
  return (
    <>
      <div className={`${styles.container} ${styles.mobileMenu}`}>
        {renderCategories(categories)}
      </div>
      <div className={styles.container}>
        {renderLinks(links, itemsOnCart, cart, trackEcommerceEvent)}
      </div>
    </>
  )
}
