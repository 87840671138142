import React from 'react'
import useResizedImageURL from '@hooks/useResizedImageURL'
import Link from 'next/link'

import styles from './styles.module.css'

export default function Logo(props) {
  const {website, config, itemsOnCart} = props
  const {logo, backgroundColor} = config
  const finalLogo = logo || website.logo
  const url = useResizedImageURL({
    fileId: finalLogo._id,
    width: 500,
    background: backgroundColor || '#fff'
  })

  if (!url) return null
  return (
    <div className={styles.container}>
      <Link href="/" legacyBehavior>
        <img src={url} alt="Logo" />
      </Link>
      <div className={styles.mobileHeader}>
        <Link href="/checkout" legacyBehavior>
          <div className={styles.cart}>
            <img src="https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FWJJ2GSynmnCmdk8rr-pedido.svg" />
            <div className={styles.cartCount}>{itemsOnCart}</div>
          </div>
        </Link>

        <div className={styles.burgerMenu}>
          <img src="https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2Fs5LdCHqhLcvBkK8Bz-menu_movil.svg" />
        </div>
      </div>
    </div>
  );
}
