import React from 'react'
import Image from '@components/Image'
import useResizedImageURL from '@hooks/useResizedImageURL'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import Link from 'next/link'

import Account from '../../Default/NavbarCPG/Account'

import Delivery from './Delivery'

import styles from './styles.module.css'

export default function Navbar(props) {
  const {website, isCheckout, userPreferences, open, setOpen, navbarConfig} = props
  const {logo, backgroundColor} = navbarConfig
  const finalLogo = logo || website.logo
  const url = useResizedImageURL({
    fileId: finalLogo._id,
    width: 500,
    background: backgroundColor || '#fff'
  })
  const CART_ICON =
    'https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FWJJ2GSynmnCmdk8rr-pedido.svg'
  const BURGER_MOBILE_MENU =
    'https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2Fs5LdCHqhLcvBkK8Bz-menu_movil.svg'

  const {me} = useQuery({
    query: gql`
      query getUserInfoNavbarTiramisu($websiteId: ID) {
        me {
          _id
          name
          email
          roles
          roleInWebsite(websiteId: $websiteId) {
            _id
            role
          }
          pointsInWebsite(websiteId: $websiteId)
        }
      }
    `,
    variables: {websiteId: website._id}
  })

  const checkoutNavbar = (
    <div className={styles.headerActions}>
      <div className={styles.logo}></div>
      <Delivery />
      <Account me={me} website={website} />
      <div className={styles.mobileHeader}>
        <div className={styles.burgerMenu} onClick={() => setOpen(!open)}>
          <Image src={BURGER_MOBILE_MENU} alt={''} height={40} width={60} />
        </div>
      </div>
    </div>
  )

  const normalNavbar = isOpen =>
    isOpen ? null : (
      <div className={styles.container}>
        <Link href="/" legacyBehavior>
          <img src={url} alt="Logo" height={50} width={55} />
        </Link>
        <div className={styles.mobileHeader}>
          <Link href="/checkout" legacyBehavior>
            <div className={styles.cart}>
              <Image src={CART_ICON} alt={''} height={28} width={30} />
              <div className={styles.cartCount}>
                {userPreferences ? userPreferences.cart.items.length : 0}
              </div>
            </div>
          </Link>
          <div className={styles.burgerMenu} onClick={() => setOpen(!open)}>
            <Image src={BURGER_MOBILE_MENU} alt={''} height={40} width={60} />
          </div>
        </div>
      </div>
    )

  if (!url) return null
  return isCheckout ? checkoutNavbar : normalNavbar(open)
}
